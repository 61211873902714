import { FunctionComponent, useMemo } from "react";
import moment from "moment";
import Chart from "react-apexcharts";
import { useIntl } from "react-intl";
import type { ApexOptions } from "apexcharts";

import { Category, ModelDimension, ModelQueryMetric, quizQuestionModel } from "@namedicinu/internal-types";

import useAnalyticsQuery from "../../../../hooks/useAnalyticsQuery";
import LoadingPlaceholder from "../../../../components/LoadingPlaceholder";
import useQuizReleases from "../../../quiz/hooks/useQuizReleases";
import { fillDateSeriesCompletness } from "../../../../helpers/analytics";

type AnalyticsUserProgressType = {
  userId: string,
  content: Category[];
};

const AnalyticsUserProgress: FunctionComponent<AnalyticsUserProgressType> = ({
  userId, content
}) => {
  const intl = useIntl();
  const dates = useMemo(() => {
    const today = moment().utc().startOf("day");
    return new Array(28).fill(null).map((_, i) => moment(today).subtract(i, "days"));
  }, []);
  const categoryIds = useMemo(() => (
    content.map((category) => category.categoryId)
  ), [content]);
  const quizReleases = useQuizReleases(categoryIds);
  const query = useMemo(() => {
    return {
      model: quizQuestionModel,
      dimensions: (
        ["date"]
      ) as ModelDimension<typeof quizQuestionModel>[],
      selection: {
        "email": userId
      },
      queryMetrics: (
        ["completness"]
      ) as ModelQueryMetric<typeof quizQuestionModel>[],
    };
  }, [userId]);
  const data = useAnalyticsQuery(query, "both");
  const series = useMemo(() => {
    if(data && quizReleases) {
      const totalQuestions = Object.values(quizReleases).reduce(
        (acb, r) => acb + r.topics.reduce(
          (acc, t) => acc + t.questions.length, 0
        ), 0
      );
      return [
        {
          name: intl.formatMessage({id: "module.user.analytics.userProgress.progress", defaultMessage: "Progress" }),
          data: fillDateSeriesCompletness(dates, data, "completness", totalQuestions)
        },
      ];
    }
    return undefined;
  }, [data, quizReleases]);
  const options = useMemo(() => {
    return {
      chart: {
        type: "area",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [4],
        curve: "smooth",
      },
      fill: {
        opacity: 0.4,
        type: "solid",
        colors: ["#DB5A42"],
      },
      labels: dates.map(date => date.valueOf()) as unknown as string[] /* HACK wrong typing in apex */,
      grid: {
        show: false,
      },
      xaxis: {
        type: "datetime",
        labels: {
          style: {
            colors: "#ffffff",
          }
        },
        crosshairs: {
          stroke: {
            color: "#ffffff",
          },
        },
      },
      yaxis: {
        show: true,
      },
      legend: {
        show: false,
      },
      colors: ["#fff"],
    } satisfies ApexOptions;
  }, [dates]);
  
  return series ? (
    <Chart id="user-progress" options={options} series={series} height={200} />
  ) : <LoadingPlaceholder />;
}

export default AnalyticsUserProgress;