import { FunctionComponent } from "react";
import { ArrowRight } from "react-feather";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import useApp from "../hooks/useApp";
import LoadingPlaceholder from "../components/LoadingPlaceholder";
// import AppInstallCard from "../components/AppInstallCard";

const IndexPage: FunctionComponent = () => {
  const { user, userView, appConfigClient } = useApp();

  return user ? (
    <Container className="page-index">
      <Row className="justify-content-center align-items-center p-3" style={{ minHeight: "80vh" }}>
        <Col xs={12} sm={10} md={8} lg={6}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h1>
                    <FormattedMessage id="pages.index.welcome" defaultMessage="Welcome to Na medicinu" />
                  </h1>
                  <p>
                    <FormattedMessage id="pages.index.welcomeText" defaultMessage="This is a web application that's going to prepare you for your studies in medicine." />
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {user.role == "registered" ? (
            <Row>
              <Col>
                <Card className="card-g-success">
                  <CardBody className="px-4 py-3">
                    <div className="h3">
                      <FormattedMessage id="pages.index.newlyRegistered" defaultMessage="You have successfully registered" />
                    </div>
                    <p>
                      <FormattedMessage id="pages.index.newlyRegisteredText" defaultMessage="Your account is going to be activated once the payment is received." />
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : user.role == "deactivated" ? (
            <Row>
              <Col>
                <Card className="card-g-warning">
                  <CardBody className="px-4 py-3">
                    <div className="h3">
                      <FormattedMessage id="pages.index.accountDeactivated" defaultMessage="You have successfully registered" />
                    </div>
                    <p>
                      <FormattedMessage id="pages.index.accountDeactivatedText" values={{email: appConfigClient.adminEmail}} defaultMessage="Your account is going to be activated once the payment is received." />
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12}>
                <div className="index-navigation-blocks">
                  {user.isUser() && (
                    <Card tag={Link} to="/user" className="card-g-primary">
                      <CardBody className="px-4 py-3 d-flex justify-content-between align-items-center">
                        <div className="h3">
                          <FormattedMessage id="pages.index.myProfile" defaultMessage="My profile" />
                        </div>
                        <ArrowRight className="d-none d-md-inline" />
                      </CardBody>
                    </Card>
                  )}
                  {userView.modules.quiz && (
                    <Card tag={Link} to="/quiz" className="card-g-danger">
                      <CardBody className="p-4 d-flex justify-content-between align-items-stretch gap-4">
                        <div className="d-flex flex-column">
                          <div className="h3">
                            <FormattedMessage id="module.quiz.title" defaultMessage="Quiz" />
                          </div>
                          <p>
                            <FormattedMessage id="pages.index.quiz.text" defaultMessage="Test your knowledge with our quiz." />
                          </p>
                        </div>
                        <div className="d-flex flex-column justify-content-end">
                          <ArrowRight className="d-none d-md-inline" />
                        </div>
                      </CardBody>
                    </Card>
                  )}
                  {userView.modules.studyMaterial && (
                    <Card tag={Link} to="/study-material" className="card-g-primary">
                      <CardBody className="p-4 d-flex justify-content-between align-items-stretch gap-4">
                        <div className="d-flex flex-column">
                          <h2>
                            <FormattedMessage id="module.study-material.title.short" defaultMessage="Materials" />
                          </h2>
                          <p>
                            <FormattedMessage id="pages.index.study-material.text" defaultMessage="Access study materials provided by your lectors." />
                          </p>
                        </div>
                        <div className="d-flex flex-column justify-content-end">
                          <ArrowRight className="d-none d-md-inline" />
                        </div>
                      </CardBody>
                    </Card>
                  )}
                  {userView.modules.video && (
                    <Card tag={Link} to="/video" className="card-g-info">
                      <CardBody className="p-4 d-flex justify-content-between align-items-stretch gap-4">
                        <div className="d-flex flex-column">
                          <h2>
                            <FormattedMessage id="module.video.title" defaultMessage="Lectures" />
                          </h2>
                          <p>
                            <FormattedMessage id="pages.index.video.text" defaultMessage="Replay lectures from your lectors." />
                          </p>
                        </div>
                        <div className="d-flex flex-column justify-content-end">
                          <ArrowRight className="d-none d-md-inline" />
                        </div>
                      </CardBody>
                    </Card>
                  )}
                  {/*<AppInstallCard />*/}
                  {user.isAdmin() ? (
                    <Card tag={Link} to="/admin" className="card-g-success">
                      <CardBody className="px-4 py-3 d-flex justify-content-between align-items-center">
                        <div className="h3">
                          <FormattedMessage id="module.admin.title" defaultMessage="Admin" />
                        </div>
                        <ArrowRight className="d-none d-md-inline" />
                      </CardBody>
                    </Card>
                  ) : user.isContentManager() ? (
                    <Card tag={Link} to="/video-manager" className="card-g-success">
                      <CardBody className="px-4 py-3 d-flex justify-content-between align-items-center">
                        <div className="h3">
                          <FormattedMessage id="module.video-manager.title" defaultMessage="Video manager" />
                        </div>
                        <ArrowRight className="d-none d-md-inline" />
                      </CardBody>
                    </Card>
                  ) : user.isLector() ? (
                    <Card tag={Link} to="/lector" className="card-g-success">
                      <CardBody className="px-4 py-3 d-flex justify-content-between align-items-center">
                        <div className="h3">
                          <FormattedMessage id="module.lector.title" defaultMessage="Lector" />
                        </div>
                        <ArrowRight className="d-none d-md-inline" />
                      </CardBody>
                    </Card>
                  ) : null}
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  ) : <LoadingPlaceholder />;
};

export default IndexPage;