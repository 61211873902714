import { Buffer } from "buffer";

import { User, VideoPublicPlayback } from "@namedicinu/internal-types";

import ApiClient from "./apiClient";
import LocalDatabase from "./localDatabase";
import { VideoLogEntry } from "./types";

const CURRENT_LOG_VERSION = 1;

export default class VideoClient {
  constructor(
    private apiClient: ApiClient,
    private localDatabase: LocalDatabase,
  ) {}

  async startSession(user: User, localSessionId: string, videoId: string): Promise<VideoPublicPlayback> {
    await this.localDatabase.storeVideoSession({
      userId: user.email,
      logVersion: CURRENT_LOG_VERSION,
      localSessionId,
      videoId,
    });
    const videoPlayback = await this.apiClient.getVideoPlayback(videoId);
    return videoPlayback;
  }

  async storeLogEntry(localSessionId: string, entry: VideoLogEntry): Promise<void> {
    await this.localDatabase.storeVideoLogEntry({ localSessionId, entry });
  }

  async storeLogEntries(localSessionId: string, entries: Array<VideoLogEntry>): Promise<void> {
    await this.localDatabase.storeVideoLogEntries(entries.map((entry) => ({ localSessionId, entry })));
  }

  async offloadLogs(user: User, currentLocalSession: string | undefined): Promise<void> {
    const sessions = await this.localDatabase.getVideoSessions(user.email);
    for (const session of sessions) {
      try {
        const entries = await this.localDatabase.getVideoLogEntries(session.localSessionId);
        if (entries.length > 0) {
          const videoLogRequest = {
            logVersion: session.logVersion || 0,
            videoSessionId: session.localSessionId,
            videoId: session.videoId,
            entries: entries.map((entry) => entry.entry),
          };

          await this.apiClient.submitVideoLog(videoLogRequest);
        }

        await this.localDatabase.clearVideoLog(session.localSessionId, session.localSessionId === currentLocalSession);
      } catch (e) {
        console.error("Failed to offload logs", e);
      }
    }
  }

  async getVideoSecret(videoId: string, keys: Record<string, string>): Promise<string> {
    const data = Buffer.from(JSON.stringify({ videoId }));
    const encoded = await crypto.subtle.digest("SHA-256", data);

    const [ivStr, keyStr] = Object.entries(keys)[0]!;
    const iv = Buffer.from(ivStr, "hex");
    const key = await crypto.subtle.importKey("raw", Buffer.from(keyStr, "hex"), "AES-CBC", false, ["encrypt"]);

    const encrypted = Buffer.from(await crypto.subtle.encrypt({ name: "AES-CBC", iv }, key, encoded));

    return encrypted.toString("base64").replace(/\//g, "_").replace(/\+/g, "-");
  }
}
