import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { Container, Row, Col, Form, FormGroup, Label, Input, Card } from "reactstrap";
import { FacebookAuthProvider, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword } from "firebase/auth";
import { useIntl, FormattedMessage } from "react-intl";

import useApp from "../hooks/useApp";
import useLocale from "../hooks/useLocale";
import AsyncButton from "../components/AsyncButton";
import { errorMessage } from "../helpers/error";

const LoginPage: FunctionComponent = () => {
  const intl = useIntl();
  const { auth, user } = useApp();
  const locale = useLocale();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<any|null>(null);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const loginEmailPassword = async () => {
    await signInWithEmailAndPassword(auth, email, password);
  }

  const loginFacebook = async () => {
    const provider = new FacebookAuthProvider();
    provider.setDefaultLanguage(locale);
    await signInWithPopup(auth, provider);
  };

  const loginGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.setDefaultLanguage(locale);
    await signInWithPopup(auth, provider);
  };

  const localizeFirebaseError = (error: any) => {
    if (typeof error.code == "string" && error.code.startsWith("auth/")) {
      const code = error.code.replace("auth/", "");
      return intl.formatMessage({ id: `page.login.error.${code}`, defaultMessage: error.message });
    }
    return error;
  }

  return (
    <div className="page-wrapper">
      <Container fluid={true} className="p-0">
        <div className="authentication-main m-0">
          <Row>
            <Col md="12">
              <div className="auth-innerright">
                <div className="authentication-box">
                  <Card className="cont">
                    <Row className="w-100">
                      <Col md={{size: 4, order: 1}} className="social-login">
                        <p>
                          <FormattedMessage id="page.login.loginWithSocial" defaultMessage="Login with social media" />
                        </p>
                        <AsyncButton color="social btn-facebook" onClick={loginFacebook} onError={setError}>
                          <FontAwesomeIcon icon={faFacebookF} />
                          <div className="mt-2">Facebook</div>
                        </AsyncButton>
                        <AsyncButton color="social btn-google" onClick={loginGoogle} onError={setError}>
                          <FontAwesomeIcon icon={faGoogle} />
                          <div className="mt-2">Google</div>
                        </AsyncButton>
                      </Col>
                      <Col md={{size: 8, order: 0}}>
                        <Form className="theme-form">
                          <h4>
                            <FormattedMessage id="page.login.login" defaultMessage="Login" />
                          </h4>
                          {(error && error.code != "auth/popup-closed-by-user") ? (
                            <div className="alert alert-danger">
                              {errorMessage(localizeFirebaseError(error))}
                            </div>
                          ) : null}
                          <div className="h6">
                            <FormattedMessage id="page.login.withEmailPassword" defaultMessage="Enter your Email and Password" />
                          </div>
                          <FormGroup>
                            <Label className="col-form-label pt-0">
                              <FormattedMessage id="page.login.email" defaultMessage="Email" />
                            </Label>
                            <Input
                              className="btn-pill"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required={true}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">
                              <FormattedMessage id="page.login.password" defaultMessage="Password" />
                            </Label>
                            <Input
                              className="btn-pill"
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required={true}
                            />
                          </FormGroup>
                          <FormGroup className="d-flex flex-wrap mt-3 mb-0">
                            <AsyncButton
                              color="primary" className="d-block w-100"
                              type="submit" onClick={loginEmailPassword}
                              onError={setError}
                            >
                              <FormattedMessage id="page.login.action.login" defaultMessage="Login" />
                            </AsyncButton>
                          </FormGroup>
                        </Form>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default LoginPage;