import { Moment } from "moment";
import { interpolateRgb } from "d3-interpolate";

import {
  StatsAggregator,
  quizQuestionModel,
  quizSessionModel,
  ModelQueryData,
  ModelQueryMetric,
  ModelQueryMetricType,
  StatsModel,
  Category,
} from "@namedicinu/internal-types";

import { QuizReleaseConfig, QuestionSetConfig } from "../types";

export function fillDateSeries<
  M extends StatsModel,
  MQ extends ModelQueryMetric<M>,
  R extends ModelQueryMetricType<M, MQ>,
>(dates: Moment[], data: ModelQueryData<M, "date", MQ>, metric: MQ, defaultValue: R): R[] {
  return dates.map((date) => {
    const entry = data.find((e) => e.date.valueOf() === date.valueOf());
    return (entry ? entry[metric] : defaultValue) as R;
  });
}

export function fillDateSeriesCompletness<M extends StatsModel, MQ extends ModelQueryMetric<M>>(
  dates: Moment[],
  data: ModelQueryData<M, "date", MQ>,
  metric: MQ,
  total: number,
): number[] {
  return dates.map((date) => {
    const entry = data.find((e) => e.date.valueOf() === date.valueOf());
    return (entry ? entry[metric] : 0) / total;
  });
}

export const scoreColor = (v: number): string => {
  if (v >= -8 && v < 0) {
    return interpolateRgb("#d4322c", "#f9f7ae")((v + 8) / 8);
  } else if (v >= 0 && v <= 4) {
    return interpolateRgb("#f9f7ae", "#22964f")(v / 4);
  } else {
    return "white";
  }
};

export const completnessColor = (v: number): string => {
  if (v >= 0 && v <= 1) {
    return interpolateRgb("#f9f7ae", "#22964f")(v);
  } else {
    return "white";
  }
};

export const aggregateHigherLevelStats = (
  data: ModelQueryData<typeof quizQuestionModel, "categoryId" | "topicId" | "area", "avg-score">,
  content: Category[],
  sort: "numeric" | "score-asc" | "score-desc",
  quizReleases: { [categoryId: string]: QuizReleaseConfig },
): Array<{
  categoryId: string;
  categoryAvgScore: number | undefined;
  topics: Array<{
    topicId: string;
    topicAvgScore: number | undefined;
    areas: Array<{
      area: string;
      areaAvgScore: number | undefined;
    }>;
  }>;
}> => {
  const aggregator = new StatsAggregator(quizSessionModel, "", "");

  const categoriesLines = content.map((category) => {
    const categoryData = aggregator.aggregatePrimary(data, ["categoryId"], { categoryId: category.categoryId }, [
      "avg-score",
    ]);

    const topicsLines = category.topics.map((topic) => {
      const topicData = aggregator.aggregatePrimary(
        data,
        ["categoryId", "topicId"],
        { categoryId: category.categoryId, topicId: topic.topicId },
        ["avg-score"],
      );
      console.log("topicData", topic, topicData);
      const areas: QuestionSetConfig["areas"] = [];
      for (const topic of quizReleases[category.categoryId]?.topics ?? []) {
        if (topic.topicId === topic.topicId) {
          for (const questionSetConfig of topic.questionSetConfigs) {
            areas.push(...questionSetConfig.areas);
          }
        }
      }

      const areasLines = areas.map((area) => {
        const areaData = aggregator.aggregatePrimary(
          data,
          ["categoryId", "topicId", "area"],
          { categoryId: category.categoryId, topicId: topic.topicId, area: area.title },
          ["avg-score"],
        );

        return {
          area: area.title,
          areaAvgScore: areaData[0] ? areaData[0]["avg-score"] : undefined,
        };
      });

      if (sort === "score-asc") {
        areasLines.sort((a, b) => (a.areaAvgScore ?? 0) - (b.areaAvgScore ?? 0));
      } else if (sort === "score-desc") {
        areasLines.sort((a, b) => (b.areaAvgScore ?? 0) - (a.areaAvgScore ?? 0));
      }

      return {
        topicId: topic.topicId,
        topicAvgScore: topicData[0] ? topicData[0]["avg-score"] : undefined,
        areas: areasLines,
      };
    });

    if (sort === "score-asc") {
      topicsLines.sort((a, b) => (a.topicAvgScore ?? 0) - (b.topicAvgScore ?? 0));
    } else if (sort === "score-desc") {
      topicsLines.sort((a, b) => (b.topicAvgScore ?? 0) - (a.topicAvgScore ?? 0));
    }

    return {
      categoryId: category.categoryId,
      categoryAvgScore: categoryData[0] ? categoryData[0]["avg-score"] : undefined,
      topics: topicsLines,
    };
  });

  if (sort === "score-asc") {
    categoriesLines.sort((a, b) => (a.categoryAvgScore ?? 0) - (b.categoryAvgScore ?? 0));
  } else if (sort === "score-desc") {
    categoriesLines.sort((a, b) => (b.categoryAvgScore ?? 0) - (a.categoryAvgScore ?? 0));
  }

  return categoriesLines;
};
