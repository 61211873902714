import { FunctionComponent, useMemo } from 'react';

import useApp from '../hooks/useApp';
import PickLocalized from '../modules/admin/components/PickLocalized';

const TopicTitle: FunctionComponent<{
  categoryId: string;
  topicId: string;
}> = ({ categoryId, topicId }) => {
  const { content } = useApp();

  const title = useMemo(() => (
    content.find((category) => category.categoryId === categoryId)?.topics.find((topic) => topic.topicId === topicId)?.title
  ), [content, categoryId, topicId]);
  return (
    <>
      {title ? (
        <PickLocalized value={title} />
      ) : (
        topicId
      )}
    </>
  )
}

export default TopicTitle;