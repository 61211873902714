import { Localized } from "@namedicinu/internal-types";

import { FunctionComponent } from "react";
import useLocale from "../../../hooks/useLocale";

const PickLocalized: FunctionComponent<{ value: Localized<string> }> = ({ value }) => {
  const locale = useLocale();

  return (
    <>
      {value[locale]}
    </>
  );
}

export default PickLocalized;