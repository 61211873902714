import type { Moment, Duration } from "moment";
import { Allow, IsUUID } from "class-validator";
import { Transform } from "class-transformer";

import { transformDuration, transformMoment } from "../helpers/transforms";
import { IsDuration, IsMoment } from "../helpers/decorators";
import { User } from "./user";

export class VideoLogEntry {
  @Allow()
  user: User;

  @IsUUID()
  videoLogId: string;
  @IsUUID()
  videoId: string;
  @IsUUID()
  videoSessionId: string;
  @IsUUID()
  clientId: string;
  @Transform(transformMoment)
  @IsMoment()
  timestamp: Moment;
  @Transform(transformDuration)
  @IsDuration()
  watchOffset: Duration;
  @Transform(transformDuration)
  @IsDuration()
  playDuration: Duration;
  @Transform(transformDuration)
  @IsDuration()
  pauseDuration: Duration;
  @Transform(transformMoment)
  @IsMoment()
  createdAt: Moment;
}
