import { Allow, IsArray, IsNumber, IsOptional, Max, Min } from "class-validator";
import { User } from "./user";

export class UserQuestionModel {
  @Allow()
  user: User;

  @IsOptional()
  @IsArray()
  @IsNumber({}, { each: true })
  @Min(-1, { each: true })
  @Max(1, { each: true })
  affinity: number[] | null;
}
