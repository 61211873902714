import { FunctionComponent, useMemo } from 'react';

import useApp from '../hooks/useApp';
import PickLocalized from '../modules/admin/components/PickLocalized';

const CategoryTitle: FunctionComponent<{
  categoryId: string;
}> = ({ categoryId }) => {
  const { content } = useApp();
  
  const title = useMemo(() => (
    content.find((category) => category.categoryId === categoryId)?.title
  ), [content, categoryId]);

  return (
    <>
      {title ? (
        <PickLocalized value={title} />
      ) : (
        categoryId
      )}
    </>
  )
}

export default CategoryTitle;