export type JsonPrimitive = string | number | boolean | null;
export interface JsonArray extends Array<JsonType> {}
export interface JsonObject {
  [prop: string]: JsonType;
}
export type JsonType = JsonPrimitive | JsonArray | JsonObject;

export const roles = ["admin", "lector", "content-manager", "user", "registered", "deactivated"] as const;
export type RoleType = (typeof roles)[number];

export const tracts = ["quiz", "video", "studyMaterial"] as const;
export type TractType = (typeof tracts)[number];

export const locales = ["sk", "cs", "en"] as const;
export type LocaleType = (typeof locales)[number];

export const paymentCompanies = ["SK", "CZ"] as const;
export type PaymentCompany = (typeof paymentCompanies)[number];

export const taskTypes = ["videoPackager", "quizReleaser", "webDeployer", "attachmentPrinter", "unknown"] as const;
export type TaskType = (typeof taskTypes)[number];

export const taskStates = ["created", "pending", "running", "success", "failed"] as const;
export type TaskState = (typeof taskStates)[number];

export const courseRegistrationStates = ["new", "approved", "rejected"] as const;
export type CourseRegistrationState = (typeof courseRegistrationStates)[number];
